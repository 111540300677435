<template>
  <img
    :class="{
      [$style.small]: props.size === 'small',
      [$style.medium]: props.size === 'medium',
      [$style.large]: props.size === 'large'
    }"
    :src="flagUrl"
    :alt="alt ?? country"
    loading="lazy"
  />
</template>

<script setup lang="ts">
/**
 * This component is inspired by https://github.com/luizzappa/vue-circle-flags.
 * The original implementation uses lazy loading for each flag, which
 * is not desired in our use case.
 */

interface Props {
  country: string;
  alt?: string;
  size?: 'small' | 'medium' | 'large';
}

const props = withDefaults(defineProps<Props>(), {
  size: 'medium',
  alt: undefined
});

const flagUrl = computed(() => `/_flags/${props.country.toLowerCase()}.svg`);
</script>

<style module>
.flag {
  width: 100%;
}

.small {
  height: 32px;
  width: 32px;
}

.medium {
  height: 62px;
  width: 62px;
}

.large {
  height: 92px;
  width: 92px;
}
</style>
